// extracted by mini-css-extract-plugin
export var breadcrumb = "styles-module--breadcrumb--v2JQS";
export var breakme = "styles-module--breakme--xOE7Y";
export var content = "styles-module--content--l1iHF";
export var header = "styles-module--header--qjTIl";
export var holder = "styles-module--holder--U5gzD";
export var howHolder = "styles-module--howHolder--eMcNk";
export var image = "styles-module--image--CaXW-";
export var kaynakca = "styles-module--kaynakca--RHbxM";
export var kidsHolder = "styles-module--kidsHolder--LInnV";
export var listHolder = "styles-module--listHolder--M1T2Y";
export var productHolder = "styles-module--productHolder--gK496";
export var rightCoughHolder = "styles-module--rightCoughHolder--UARsN";
export var typesHolder = "styles-module--typesHolder--ocGFb";
export var whatHolder = "styles-module--whatHolder--qHgzZ";
export var whyHolder = "styles-module--whyHolder--KUybZ";